<template>

  <div class="logo"><img src="@/assets/logo_old.png" alt="" width="40" height="40"><img src="@/assets/logo.png" alt="" width="40" height="40"></div>

  <ul class="navigation-links">
    <li>
      <router-link
          :to="{ name: 'home' }"
          :class="{ active: $route.name === 'home' }"
      >{{ $t('navbar.home') }}</router-link>
    </li>
    <li>
      <a-dropdown class="search-dropdown">
        <a class="ant-dropdown-link" :class="{ active: $route.name === 'search' }">
          <span>{{ $t('navbar.search') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
            <path d="M11.25 1L6.125 6.125L1 1" stroke="#748594" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <router-link
                  :to="{ name: 'advanced-search' }"
                  :class="{ active: $route.name === 'advanced-search' }"
              >{{ $t('navbar.advancedSearch') }}</router-link>
            </a-menu-item>
            <a-menu-item>
              <router-link
                  :to="{ name: 'search' }"
                  :class="{ active: $route.name === 'search' }"
              >{{ $t('navbar.map') }}</router-link>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </li>
    <li>
      <router-link
          :to="{ name: 'person' }"
          :class="{ active: $route.name === 'person' }"
      >{{ $t('navbar.person') }}</router-link>
    </li>
    <li>
      <router-link
          :to="{ name: 'faq' }"
          :class="{ active: $route.name === 'faq' }"
      >{{ $t('navbar.faq') }}</router-link>
    </li>
    <li>
      <router-link
          :to="{ name: 'suggest' }"
          :class="{ active: $route.name === 'suggest' }"
      >{{ $t('navbar.suggestMaterial') }}</router-link>
    </li>
    <li>
      <router-link
          :to="{ name: 'contacts' }"
          :class="{ active: $route.name === 'contacts' }"
      >{{ $t('navbar.contacts') }}</router-link>
    </li>
  </ul>

  <ul class="language-switcher">
    <li>
      <a
        href="#"
        @click.prevent="switchLang('ru')"
        :class="{ active: $i18n.locale === 'ru' }"
      >RU</a>
    </li>
    <li>
      <a
        href="#"
        @click.prevent="switchLang('de')"
        :class="{ active: $i18n.locale === 'de' }"
      >DE</a>
    </li>
    <li>
      <a
        href="#"
        @click.prevent="switchLang('en')"
        :class="{ active: $i18n.locale === 'en' }"
      >EN</a>
    </li>
  </ul>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const { locale } = useI18n()
const switchLang = lang => {
    locale.value = lang
    localStorage.setItem('locale', lang)
    document.querySelector('html').setAttribute('lang', lang)
}

</script>

<style scoped>

</style>