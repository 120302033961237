import Vuex from 'vuex'
import player from './modules/player'

const store = new Vuex.Store({
  modules: {
    player,
  },
  strict: false,
})

export default store