const messages = {
  navbar: {
    home: 'Heimat',
    search: 'Suche',
    faq: 'FAQ',
    suggestMaterial: 'Stoff vorschlagen',
    contacts: 'Kontakte',
    map: 'Karte öffnen',
    advancedSearch: 'Erweiterte suche',
    person: 'Person'
  },
  homeView: {
    title: 'INTERACTIVE ARCHIVE OF GERMANS OF KAZAKHSTAN',
    body: 'Sie befinden sich auf der Website des Interaktiven Archivs der Deutschen Kasachstans, auf der die Materialien aus Staats- und Familienarchiven zur Geschichte und Kultur der Kasachstandeutschen sammelt und präsentiert werden.\n' +
        '\n' +
        'Bei der Forschung der Vergangenheit greifen wir auf verschiedene Quellen, darunter Archivdokumente, die lautlos die Informationen über vergangene Ereignisse, Phänomene, Fakten und Namen aufbewahren, zurück. Ist es wirklich lautlos? Beim sorgfältigen Sammeln, Recherchieren und Bewahren von Informationen aus Archiven zu verschiedenen Themen in der Geschichte und Kultur der kasachischen Deutschen haben wir erkannt, dass die Archivmaterialien oft bestimmte Probleme der Vergangenheit lautstark zum Ausdruck bringen können. Und umso wertvoller ist es heute, sich auf der Suche nach Antworten auf viele drängende Fragen und Herausforderungen unserer Zeit der Dokumentargeschichte zuzuwenden.',
    main_title: 'WAS IST DAS ARCHIV FÜR SIE PERSÖNLICH NÜTZLICH?',
    main_desc: 'Von 2022 bis 2023 hat das Projektteam umfangreiche Arbeiten in den Staatsarchiven der Republik Kasachstan durchgeführt und verschiedene Materialien aus den Familienarchiven der kasachischen Deutscher gesammelt. Der aufgedeckte Materialsatz wurde digitalisiert und in den präsentierten Rubriken systematisiert. Auf der Website haben Sie die Möglichkeit, Einblick in verschiedene Themen zur Geschichte und Kultur der Deutschen Kasachstans zu gewinnen.\n' +
        '\n' +
        'Bitte beachten Sie, dass die Website elektronische Kopien von Archivdokumenten enthält und keine Archivbescheinigungen bereitstellt. Um Archivbescheinigungen zu erhalten, sollten Sie sich an die Amts- und Staatsarchivinstitutionen der Republik Kasachstan wenden.',
    how_it_works_title: 'WIE ES FUNKTIONIERT?',
    how_it_works_desc: 'Sie können zu „Erweiterte Suche“ gehen, um alle auf der Website verfügbaren Veröffentlichungen, unterteilt in Abschnitte, in Sicht zu bekommen bzw. gezielt die Informationen zu finden, die Sie benötigen, und auf einen Bereich auf der Karte rechts anklicken, um alle Veröffentlichungen zu diesem Bereich anzuzeigen. Das Gleiche kann über den Menüpunkt „Suchen“ erfolgen.\n' +
        '\n' +
        'Über den Menüpunkt „Datenbank“ können Sie nach einer bestimmten Person und Informationen über diese suchen.\n' +
        '\n' +
        'Falls Sie über Materialien verfügen, die Sie zur Veröffentlichung auf unserer Website einreichen möchten, verwenden Sie den Menüpunkt „Materialien einreichen“.\n',
    copyright: 'Alle Materialien auf der Website dienen ausschließlich Informationszwecken. Das teilweise oder vollständige Kopieren von Site-Materialien ohne schriftliche Genehmigung der Site-Administration ist verboten! Alle Materialien auf der Website sind gemäß den gesetzlichen Bestimmungen geschützt, einschließlich Urheberrecht und verwandten Schutzrechten.',

  }
}

export default {
  locale: 'de',
  messages,
}
