<template>
  <div class="code-box">
    <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
      <template #extra>
        <router-link type="primary" to="/">
          <a-button type="primary">Back home</a-button>
        </router-link>
      </template>
    </a-result>
  </div>
</template>
<script>
import { useMeta } from "vue-meta";
export default {
  name: "DeError404",
  components : {
  },
  setup() {
    useMeta({
      title: 'Not found',
      htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
    });
  }
}
</script>

<style>
.code-box {
  text-align: center;
  padding: 42px 24px 50px;
}
</style>