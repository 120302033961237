const PLAYER_STATUS = 'STATUS_PAUSED'

const state = {
  playerStatus: sessionStorage.getItem('player-status') || ''
}
const getters = {
  playerStatus(state) {
    return state.playerStatus
  }
}

const mutations = {
  [PLAYER_STATUS] (state, data) {
    state.playerStatus = data;
    sessionStorage.setItem('player-status', data)
  },
}

const actions = {
  updateStatus ({commit}, status) {
    commit(PLAYER_STATUS, status)
    return status
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}