const messages = {
  navbar: {
    home: 'Главная',
    search: 'Поиск',
    faq: 'FAQ',
    suggestMaterial: 'Предложить материалы',
    contacts: 'Контакты',
    map: 'Открыть карту',
    advancedSearch: 'Расширенный поиск',
    person: 'База данных'
  },
  homeView: {
    title: 'Интерактивный архив немцев Казахстана',
    body: 'Вы находитесь на сайте Интерактивного архива немцев Казахстана, на котором собраны и представлены материалы из государственных и семейных архивов по истории и культуре казахстанских немцев.\n' +
        '\n' +
        'Изучая прошлое, мы обращаемся к разным источникам, в том числе и к архивным документам, безмолвно хранящим сведения о прошедших событиях, явлениях, фактах, именах. А действительно ли безмолвно? Скрупулезно собирая, исследуя и сохраняя информацию из архивов по различным темам истории и культуры казахстанских немцев, мы увидели, что, зачастую, архивные материалы могут достаточно громко «озвучить» те или иные проблемы прошлого. И тем ценнее сегодня обращение к документальной истории в поисках ответов на многие актуальные вопросы и вызовы современности.\n' +
        '\n',
    main_title: 'ЧЕМ ПОЛЕЗЕН АРХИВ ЛИЧНО ВАМ?',
    main_desc: 'С 2022 по 2023 гг. коллективом проекта была проведена большая работа в государственных архивах Республики Казахстан, осуществлен сбор материалов из семейных архивов казахстанских немцев. Выявленный комплекс материалов был оцифрован и систематизирован в представленные рубрики. С помощью сайта Вы получите возможность ознакомиться с различной тематикой по истории и культуре немцев Казахстана.\n' +
        '\n' +
        'Обращаем Ваше внимание, что на сайте представлены электронные копии архивных документов и сайт не предоставляет архивных справок. За получением архивных справок Вам следует обратиться в ведомственные и государственные архивные учреждения Республики Казахстан.',
    how_it_works_title: 'КАК ЭТО РАБОТАЕТ?',
    how_it_works_desc: 'Вы можете перейти в «Расширенный поиск», чтобы увидеть все имеющиеся на сайте публикации, разбитые по разделам, или найти нужную конкретно Вам информацию, а также кликнуть по области на карте справа, чтобы увидеть все публикации, относящиеся к данной области. Всё то же самое можно проделать, воспользовавшись пунктом меню «Поиск».\n' +
        '\n' +
        'Обратившись к пункту меню «База данных», Вы можете осуществить поиск конкретного человека и информации о нём.\n' +
        '\n' +
        'Если у Вас есть материалы, которые Вы хотели бы передать для размещения на нашем сайте, воспользуйтесь пунктом меню «Предложить материалы».\n',
    copyright: 'Все материалы на сайте предоставлены для ознакомления. Частичное или полное копирование материалов сайта без письменного разрешения администрации сайта запрещено! Все материалы, находящиеся на сайте, охраняются в соответствии с законодательством, в том числе, об авторском праве и смежных правах.',
    open_map: 'Открыть карту',
    advancedSearch: 'Расширенный поиск',
    articles: 'Статей',
    photo: 'Фото',
    video: 'Видео',
    podcasts: 'Подкасты и интервью',
    archives: 'Семейные архивы',
    search_throughout: 'Искать на всей территории РК',
    word_for_search: 'Введите слова, по которым вы хотите осуществить поиск или воспользуйтесь системой рубрик',
    search_by_word: 'Поиск по слову',
    enter_the_tag: 'Введите рубрику',
    clear_all: 'Очистить все'
  },
  advancedSearchView: {
    advancedSearch: 'Расширенный поиск',
    tema: 'Тема',
    region: 'Регион',
    location_archive: 'Местонахождение архива',
    period: 'Период',
    select_date: 'Выберите дату',
    articles: 'Статей',
    photo: 'Фото',
    video: 'Видео',
    podcasts: 'Подкасты и интервью',
    archives: 'Семейные архивы',
    show_all: 'Показать все',
  },
  searchView: {
    articles: 'Статей',
    photo: 'Фото',
    video: 'Видео',
    podcasts: 'Подкасты и интервью',
    archives: 'Семейные архивы',
    search_throughout: 'Искать на всей территории РК',
    word_for_search: 'Введите слова, по которым вы хотите осуществить поиск или воспользуйтесь системой рубриков',
  },
  faqView: {
    title: 'Не нашли ответ на вопрос?',
    second_title: 'Задайте нам его напрямую',
    name: 'Имя',
    phone: 'Номер телефона',
    question: 'Вопрос',
    send: 'Отправить'
  },
  suggestView: {
    title: 'Предложить материалы',
    name: 'ФИО',
    text: 'Какие материалы у вас есть?',
    send: 'Отправить',
    text_two: {
      first_line: 'Пожалуйста, заполните форму с подробным описанием' ,
      second_line: 'имеющихся материалов. Мы свяжемся с вами через',
      third_line: 'электронную почту, указанную в заявке'
    }
  },
  contactView: {
    title: 'Контакты',
    write: 'Написать',
    to_email: 'на email',
    call_write: 'Позвонить',
    by_number: 'по номеру',
    contact_us: 'Свяжитесь с нами, заполнив форму ниже',
    fio: 'ФИО',
    phone_number: 'Номер телефона',
    write_text: 'Напишите сообщение',
    send: 'Отправить',
    you_can_contact_us: 'Вы можете связаться с нами удобным для вас способом',
  },
  personView: {
    search: 'Поиск',
    enter: 'Введите',
    select: 'Выберите',
    search_two: 'Найти',
    clear: 'Очистить',
    please_provide: 'Пожалуйста, укажите максимально корректные данные',
    personal_data: {
      title: 'Личные данные',
      name: 'Имя',
      surname: 'Фамилия',
      patronymic: 'Отчество',
      year_of_birth: 'Год рождения',
      object: 'Объект',
      year: 'Год',
    },
    place_of_birth: {
      title: 'Место рождения',
      region: 'Регион',
      district: 'Район',
      city: 'Населенный пункт',
      meo: 'Военкомат',
      date: 'Дата'
    },
    place_of_residence: {
      title: 'Место проживания',
      period: 'Период',
      cause: 'Причина убытия'
    }
  },
  personDetailView: {
    person: 'База данных',
    archive: 'Архив',
    personal_data: 'Личные данные',
    year_of_birth: 'Год рождения',
    place_of_birth: 'Место рождения',
    nationality: 'Национальность',
    citizenship: 'Гражданство',
    social_status: 'Социальный статус',
    education: 'Образование',
    party_membership: 'Партийность',
    repressions: 'Сведения о репрессиях',
    type_of_repression: 'Вид репрессий',
    place_of_work: 'Место работы',
    date_of_mobilization: 'Дата мобилизации',
    termination_date: 'Дата прекращения',
    place_of_residence: 'Место проживания',
    occupation: 'Профессия',
    mobilized_by: 'Кем мобилизован',
    reason: 'Причина',
  },
  articles: {
    title: 'Статьи',
    did_you_know_that: 'Знали ли вы, что...',
    did_you_know_more: 'Некоторых понятий нет ни в одном другом языке. Слово «fremdschämen», например, описывает чувство неловкости или стыда за поступок другого человека. А «Fernweh» является противоположностью «Heimweh» (тоски по родине) и описывает тягу к дальним странствиям и приключениям.'
  }
}

export default {
  locale: 'ru',
  messages,
}
