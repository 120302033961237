<template>
  <div id="audio-player-root">
    <div>
      <audio
          style="display:none"
          ref="player"
          :id="playerId"
      >
        <source :src="url" type="audio/mpeg" />
      </audio>
    </div>

    <div
        class="w-3/4 max-w-5xl bg-gray-200 border border-gray-300 px-2 pt-2 mt-4 shadow-md"
        style="margin: auto;"
    >
      <div id="player-row" class="inline-flex flex-wrap w-full">
        <div
            id="progress-bar"
            class="flex-grow bg-white border border-blue-200"
        >
          <div class="overlay-container relative w-full h-full">
            <a-slider
                @change="updateCurrentTime"
                v-model:value="playbackTime"
                :max="audioDuration"
                :tooltip-visible="false"
                :handle-style="{ backgroundColor: '#0764B3', borderColor: '#0764B3' }"
                :track-style="{ backgroundColor: '#0764B3' }"
            />

            <div
                class="flex w-full justify-between absolute top-0 bottom-0 right-0 left-0 px-2 pointer-events-none"
            >
              <span :class="{ 'hide': !audioLoaded }" class="text-sm" v-html="elapsedTime()"></span>
              <span :class="{ 'hide': !audioLoaded }" class="text-sm" style="float: right" v-html="totalTime()"></span>
            </div>
          </div>
        </div>
        <div id="button-div" class="flex-initial player-button-wrapper pr-3" :class="{ loading: !audioLoaded }">
          <a-spin class="player-loader" :indicator="indicator" />
          <a-button
              type="link"
              style="padding: 0; width: 50px; height: 50px"
              @click="toggleAudio()"
              v-show="!isPlaying"
          >
            <svg
                width="50"
                height="50"
                class="play-button text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill="#0764B3"
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                  clip-rule="evenodd"
              />
            </svg>
          </a-button>
          <a-button
              type="link"
              style="padding: 0; width: 50px; height: 50px"
              @click="toggleAudio()"
              v-show="isPlaying"
          >
            <svg
                width="50"
                height="50"
                class="play-button text-orange-400 hover:text-orange-400 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
              <path
                  fill="#0764B3"
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
              />
            </svg>
          </a-button>
        </div>
      </div>
      <h5 class="title" v-html="title"></h5>
      {{ audioStatus }}
    </div>

    <!-- outer gray border -->
  </div>
  <!-- white bg -->
</template>

<script setup>
import {onMounted, nextTick, watch, ref, h, computed} from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import {useStore} from "vuex";

const props = defineProps({
  url: {
    type: String,
    default: "",
  },
  playerId: {
    type: String,
    default: ""
  },
  title: {
    type: String,
    default: ""
  }
})

const emits = defineEmits()

const store = useStore()

/**
 * playbackTime = local var that syncs to audio.currentTime
 * audioDuration = duration of audio file in seconds
 * isPlaying = boolean (true if audio is playing)
 *
 **/

const player = ref(null)

const playerStatus = computed(() => store.getters.playerStatus)

let playbackTime = ref(0),
  audioDuration = ref(100),
  audioLoaded = ref(false),
  isPlaying = ref(false),
  listenerActive = false,
  title = props.title,
  indicator = h(LoadingOutlined, {
    style: {
      fontSize: '52px',
      color: '#0764b3'
    },
    spin: true,
  });

// Set the range slider max value equal to audio duration
const initSlider = () => {
  let audio = player._value;
  if (audio) {
    audioDuration.value = Math.round(audio.duration);
  }
};

// Convert audio current time from seconds to min:sec display
const convertTime = (seconds) => {
  const format = val => `0${Math.floor(val)}`.slice(-2);
  let hours = seconds / 3600;
  let minutes = (seconds % 3600) / 60;
  return [minutes, seconds % 60].map(format).join(":");
};

// Display the audio time elapsed so far
const elapsedTime = () => {
  let audio = player._value;
  if (audio) {
    let seconds = audio.currentTime;
    return convertTime(seconds);
  } else {
    return '00:00';
  }
};

// Show the total duration of audio file
const totalTime = () => {
  let audio = player._value;
  if (audio) {
    let seconds = audio.duration;
    return convertTime(seconds);
  } else {
    return '00:00';
  }
};

// Playback listener function runs every 100ms while audio is playing
const playbackListener = function (e) {
  let audio = player._value;
  // Sync local 'playbackTime' var to audio.currentTime and update global state
  if (audio) {
    playbackTime.value = audio.currentTime;
    //Add listeners for audio pause and audio end events
    audio.addEventListener("ended", endListener);
    audio.addEventListener("pause", pauseListener);
  }
};

// Function to run when audio is paused by user
const pauseListener = function () {
  isPlaying.value = false;
  listenerActive = false;
  cleanupListeners();
};

// Function to run when audio play reaches the end of file
const endListener = function () {
  isPlaying.value = false;
  listenerActive = false;
  cleanupListeners();
};

// Remove listeners after audio play stops
const cleanupListeners = () => {
  let audio = player._value;
  if (audio) {
    audio.removeEventListener("timeupdate", playbackListener);
    audio.removeEventListener("ended", endListener);
    audio.removeEventListener("pause", pauseListener);
  }
  // console.log("All cleaned up!");
};

const toggleAudio = () => {
  let audio = player._value;
  //var audio = document.getElementById("audio-player");
  if (audio.paused) {
    audio.play();
    isPlaying.value = true;
  } else {
    audio.pause();
    isPlaying.value = false;
  }
  emits('status', audio.paused)
};

const updateCurrentTime = (value) => {
  player._value.currentTime = value;
}

onMounted(() => {
  // nextTick code will run only after the entire view has been rendered
  nextTick(() => {

    let audio = player._value;
    //Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider
    // "loademetadata" Event https://www.w3schools.com/tags/av_event_loadedmetadata.asp
    audio.addEventListener(
        "loadedmetadata",
        function(e) {
          initSlider();
        }.bind(this)
    );
    // "canplay" HTML Event lets us know audio is ready for play https://www.w3schools.com/tags/av_event_canplay.asp
    audio.addEventListener(
        "canplay",
        function(e) {
          audioLoaded.value = true;
        }.bind(this)
    );
    // Wait for audio to begin play, then start playback listener function
    watch(isPlaying, () => {
      if (isPlaying.value) {
        let audio = player._value;
        initSlider();
        //console.log("Audio playback started.");
        //prevent starting multiple listeners at the same time
        if (!listenerActive) {
          listenerActive = true;
          audio.addEventListener("timeupdate", playbackListener)
        }
      }
    })

    // Update current audio position when user drags progress slider
    watch(playbackTime, (playbackTime) => {
      let audio = player._value;
      let diff = Math.abs(playbackTime.value - audio.currentTime);

      //Throttle synchronization to prevent infinite loop between playback listener and this watcher
      if (diff > 0.01) {
        audio.currentTime = playbackTime.value;
      }
    });

    audio.play();
    isPlaying.value = true;
  })
});

watch(playerStatus, (status) => {
  if (status === 'paused') {
    let audio = player._value;
    audio.pause()
    isPlaying.value = false;
  }
  if (status === 'playing') {
    let audio = player._value;
    audio.play()
    isPlaying.value = true;
  }
})

</script>

<style scoped>
#progress-bar{
  margin-bottom: -20px;
}
.text-sm.hide {
  opacity: 0;
}
#progress-bar .text-sm {
  font-family: "Mont", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4C5965;
}
.title {
  font-family: "Mont", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #4C5965;
  text-align: center;
  margin-top: 40px;
}
.player-button-wrapper {
  position: relative;
  text-align: center;
  clear: both;
  top: -25px;
}
#button-div .ant-btn-link {
  position: absolute;
  left: 50%;
  margin-left: -25px;
}
.player-loader.ant-spin-spinning {
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 0;
  opacity: 0;
  z-index: -1;
}
.player-button-wrapper.loading .player-loader{
  opacity: 1;
}
/* Play/Pause Button */
input[type="range"] {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 0; /* iOS */
  background: transparent;
}
input[type="range"]:focus {
  outline: none;
}
::-webkit-slider-runnable-track {
  background: #fff;
}
/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0; /* 1 */
  height: 40px;
  background: #fff;
  box-shadow: -100vw 0 0 100vw dodgerblue;
  border: none; /* 2px solid #999; */
}
::-moz-range-track {
  height: 40px;
  background: #ddd;
}
::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 0; /* 20px; */
  border: none; /* 3px solid #999; */
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw dodgerblue;
  box-sizing: border-box;
}
::-ms-fill-lower {
  background: dodgerblue;
}
::-ms-thumb {
  background: #fff;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}
::-ms-ticks-after {
  display: none;
}
::-ms-ticks-before {
  display: none;
}
::-ms-track {
  background: #ddd;
  color: transparent;
  height: 40px;
  border: none;
}
::-ms-tooltip {
  display: none;
}
</style>