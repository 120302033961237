<template>
  <div>
    <div class="filter-block">
      <label>{{ $t('advancedSearchView.tema') }}</label>
      <a-select
          ref="select"
          v-model:value="params.topic"
          :options="topics"
          show-search
      >
      </a-select>
      <label>{{ $t('advancedSearchView.region') }}</label>
      <a-select
          ref="select"
          v-model:value="params.state"
          :options="states"
          show-search
      >
      </a-select>
      <label>{{ $t('advancedSearchView.location_archive') }}</label>
      <a-select
          ref="select"
          v-model:value="params.region"
          :options="regions"
          show-search
      >
      </a-select>
      <label>{{ $t('advancedSearchView.period') }}</label>
      <div class="period-picker">
        <a-date-picker
            class="date-from"
            v-model:value="params.from"
            picker="year"
            :value-format="dateFormat"
            :format="dateFormat"
            :placeholder="$t('advancedSearchView.select_date')"
        >
          <template #suffixIcon>
            <icon>
              <template #component>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                  <path d="M11.25 1L6.125 6.125L1 1" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
            </icon>
          </template>
        </a-date-picker>
        <a-date-picker
            class="date-to"
            v-model:value="params.to"
            picker="year"
            :value-format="dateFormat"
            :format="dateFormat"
            :placeholder="$t('advancedSearchView.select_date')"
        >
          <template #suffixIcon>
            <icon>
              <template #component>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                  <path d="M11.25 1L6.125 6.125L1 1" stroke="#0764B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
            </icon>
          </template>
        </a-date-picker>
      </div>
    </div>
  </div>
</template>

<script setup>

import {onMounted, ref} from "vue";
import Service from "@/services/api";

const props = defineProps({
  params: {
    type: Object,
    default: null
  },
})

const params = props.params
const dateFormat = 'YYYY';

let topics = ref([]);
let states = ref([]);
let regions = ref([]);

const getData = () => {
  Service.getReferences().then((res) => {
    topics.value = res.data?.topics;
    states.value = res.data?.states;
    regions.value = res.data?.regions;
  });
}

onMounted(() => {
  getData(params);
})

</script>

<style>
html[lang=en] .layout .container .home .period-picker .ant-picker .ant-picker-input:before  {
  content: "From:";
}

html[lang=en] .layout .container .home .period-picker .ant-picker.date-to .ant-picker-input:before {
  content: "To:";
}
</style>