<template>
  <div class="search-block container-fluid">
    <a-input class="main-search-input" v-model:value="params.search" :placeholder="$t('homeView.search_by_word')">
      <template #prefix>
        <img src="@/assets/search.svg" alt="" />
      </template>
    </a-input>
    <a-row>
      <a-col :span="19">
        <a-select
            class="tags-select"
            mode="tags"
            ref="select"
            v-model:value="selected"
            :options="tags"
            :field-names="{ label: 'label', value: 'label' }"
            :placeholder="$t('homeView.enter_the_tag')"
            @change="onChangeTags"
            show-search
        />
      </a-col>
      <a-col :span="5">
        <a-button class="tags-clear-btn">
          <template #icon>
            <icon>
              <template #component>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5Z" stroke="#005EA6" stroke-width="1.5" stroke-miterlimit="10"/>
                  <path d="M15 9.5L9 15.5" stroke="#005EA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15 15.5L9 9.5" stroke="#005EA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </template>
            </icon>
          </template>
          <span @click="resetFilter">{{ $t('homeView.clear_all') }}</span>
        </a-button>
      </a-col>
    </a-row>
    <a-row>
      <a-tag style="visibility: hidden" closable v-for="tag in selected" :key="tag.value">{{ tag }}</a-tag>
    </a-row>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  params: {
    type: Object,
    default: null
  },
  tags: {
    type: Array,
    default: []
  }
})
const emits = defineEmits();
const tags = props.tags
let selected = ref([])

const onChangeTags = (e) => {
  emits('tag-update', e);
}

const resetFilter = () => {
  emits('clear-filter');
  selected.value = []
}

</script>

<style scoped>

</style>