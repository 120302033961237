<template>
  <div class="home container-fluid" id="podcasts-view-page">
    <a-breadcrumb>
      <template #separator>
        <icon>
          <template #component>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.875 14.25L12 10.125L7.875 6" stroke="#4C5965" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </template>
        </icon>
      </template>
      <a-breadcrumb-item><router-link to="/region">Архив</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>Подкасты и интервью</a-breadcrumb-item>
    </a-breadcrumb>
    <h3 class="page-title">Подкасты и интервью</h3>
    <a-form
        :model="params"
        name="basic"
        autocomplete="off"
    >
      <a-row>
        <a-col :span="16">
          <SearchBlock
              v-if="tags_list.length"
              :params="params"
              :tags="tags_list"
              @clear-filter="clearFilter"
              @tag-update="tagUpdate"
          />
          <div class="articles-list-wrapper audio-list">
            <a-pagination
                v-if="total > pageSize"
                v-model:current="page"
                v-model:pageSize="pageSize"
                :show-size-changer="false"
                :total="total"
            />
            <div class="articles-list">
              <div
                  v-if="podcasts.length"
                  class="article-item"
                  v-for="item in podcasts"
                  :key="item.id"
                  @click=""
              >
                <div class="article-text">
                  <div v-if="audioId === item.id">
                    <a-button
                        v-if="status === 'stopped'"
                        type="link"
                        @click="playAudio(item)"
                    >
                      <svg
                          class="play-button text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                      >
                        <path
                            fill="#0764B3"
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                            clip-rule="evenodd"
                        />
                      </svg>
                    </a-button>
                    <a-button
                        v-if="status === 'playing'"
                        type="link"
                        @click="pauseAudio"
                    >
                      <svg
                          class="play-button text-orange-400 hover:text-orange-400 cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                      >
                        <path
                            fill="#0764B3"
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                        />
                      </svg>
                    </a-button>
                    <a-button
                        v-if="status === 'paused'"
                        type="link"
                        @click="resumeAudio"
                    >
                      <svg
                          class="play-button text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                      >
                        <path
                            fill="#0764B3"
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                            clip-rule="evenodd"
                        />
                      </svg>
                    </a-button>
                  </div>
                  <div v-else>
                    <a-button
                        type="link"
                        @click="playAudio(item)"
                    >
                      <svg
                          class="play-button text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                      >
                        <path
                            fill="#0764B3"
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                            clip-rule="evenodd"
                        />
                      </svg>
                    </a-button>
                  </div>
                  <h6 v-html="item.title"></h6>
                </div>
                <div class="duration">{{ item.duration }}</div>
              </div>
              <div class="empty-wrapper" v-else>
                <a-empty><template #description></template></a-empty>
              </div>
            </div>
            <a-pagination
                v-if="total > pageSize"
                v-model:current="page"
                v-model:pageSize="pageSize"
                :show-size-changer="false"
                :total="total"
            />
          </div>
        </a-col>
        <a-col :span="8">
          <FilterBlock :params="params" />
          <div class="hint-block">
            <h5>Знали ли вы, что...</h5>
            <p>Некоторых понятий нет ни в одном другом языке. Слово «fremdschämen», например, описывает чувство неловкости или стыда за поступок другого человека. А «Fernweh» является противоположностью «Heimweh» (тоски по родине) и описывает тягу к дальним странствиям и приключениям.</p>
          </div>
        </a-col>
      </a-row>
    </a-form>
    <audio-player @status="updateStatus" :status="status" v-if="playerKey" class="mt-5" :url="audioUrl" :title="audioTitle" :key="playerKey" player-id="audio-player"></audio-player>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { ref, toRefs, reactive, onMounted, watch } from "vue";
import { useMeta } from "vue-meta";
import Icon from "@ant-design/icons-vue"
import SearchBlock from "@/components/SearchBlock";
import FilterBlock from "@/components/FilterBlock";
import Service from "@/services/api";
import AudioPlayer from "@/components/AudioPlayer";
import {useStore} from "vuex";
useMeta({
  title: 'Podcasts view',
  htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
});

const store = useStore()

const audioId = ref(0)
const audioUrl = ref('')
const audioTitle = ref('')

let playerKey = ref(0)

let params = reactive({
  page: 1,
  search: '',
  tags: [],
  topic: null,
  state: null,
  region: null,
  from: null,
  to: null
})

let pageSize = ref(10);
let total = ref(0);
let status = ref('stopped')

const playAudio = item => {
  audioId.value = item.id
  audioUrl.value = item.src
  audioTitle.value = item.title
  status.value = 'playing'
  store.dispatch('updateStatus', 'playing')
  playerKey.value++
}

const pauseAudio = () => {
  status.value = 'paused'
  store.dispatch('updateStatus', 'paused')
}

const resumeAudio = () => {
  status.value = 'playing'
  store.dispatch('updateStatus', 'playing')
}

const updateStatus = (value) => {
  status.value = value ? 'paused' : 'playing'
}

const router = useRouter()
const route = useRoute()
const podcasts = ref([])
let tags_list = ref([])

const tagUpdate = (tags) => {
  params.tags = tags
}

const clearFilter = () => {
  params.tags = []
  params.search = ''
}

const getData = (params) => {
  Service.getPodcasts(params).then((res) => {
    podcasts.value = res.data?.data;
    pageSize.value = res.data?.meta?.per_page;
    total.value = res.data?.meta?.total;
    tags_list.value = res.data?.tags;
  }).then(() => {
    let div = document.getElementById('podcasts-view-page');
    if (div)
      div.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
  });
}

onMounted(() => {
  params.search = route.query.search ?? ''
  params.tags = route.query.tags ?? []
  params.region = route.query.region ?? null
  if (route.query.region === '0')
    params.region = null
  getData(params);
})

watch(params, () => {
  router.push({
    name: 'podcasts',
    query: {
      ...route.query,
      ...params,
    },
  })
  getData({
    ...route.query,
    ...params,
  })
})

let { page, search } = toRefs(params)

</script>
