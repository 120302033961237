<template>
  <div class="home container-fluid" id="advanced-search-page">
    <a-row class="advanced-search-filters">
      <a-col :span="24">
        <h3 class="page-title">{{ $t('advancedSearchView.advancedSearch') }}</h3>
      </a-col>
      <a-col :span="16">
        <SearchBlock :params="params" @clear-filter="clearFilter" :tags="tags_list" :key="searchBlockKey" />
      </a-col>
      <a-col :span="8">
        <FilterBlock :params="params" />
      </a-col>
    </a-row>
    <Carousel
        :data="articles"
        :title="$t('advancedSearchView.articles')"
        :count="articlesCount"
        :index-page="'articles'"
        :details-page="'article-view'"
    />
    <Carousel
        :data="video"
        :title="$t('advancedSearchView.video')"
        :count="videoCount"
        :index-page="'video'"
        :details-page="'video-view'"
    />
    <Carousel
        :data="photo"
        :title="$t('advancedSearchView.photo')"
        :count="photoCount"
        :index-page="'photo'"
        :details-page="'photo-view'"
    />
    <Carousel
        :data="archives"
        :count="archivesCount"
        :title="$t('advancedSearchView.archives')"
        :index-page="'archives'"
    />
    <Carousel
        :data="podcasts"
        :count="podcastsCount"
        :title="$t('advancedSearchView.podcasts')"
        :index-page="'podcasts'"
    />
  </div>
</template>

<script setup>
import { useMeta } from "vue-meta";
import {onMounted, reactive, ref} from "vue";
import SearchBlock from "@/components/SearchBlock";
import FilterBlock from "@/components/FilterBlock";
import Service from "@/services/api";
import Carousel from "@/components/Carousel";

useMeta({
  title: 'Advanced search view',
  htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
});

let params = reactive({
  page: 1,
  search: '',
  tags: [],
  topic: null,
  state: null,
  region: null,
  from: null,
  to: null
})

const clearFilter = () => {
  params.tags = []
  params.search = ''
}

let searchBlockKey = ref(0)
let tags_list = ref([])

let articles = ref([])
let video = ref([])
let photo = ref([])
let archives = ref([])
let podcasts = ref([])

let articlesCount = ref(0)
let videoCount = ref(0)
let photoCount = ref(0)
let archivesCount = ref(0)
let podcastsCount = ref(0)

onMounted(() => {
  // let div = document.getElementById('advanced-search-page');
  let div = document.getElementById('app');
  if (div)
    div.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });

  articlesCount.value = 0
  videoCount.value = 0
  photoCount.value = 0
  archivesCount.value = 0
  podcastsCount.value = 0

  Service.getSearchResults(params).then((res) => {
    articles.value = res.data?.articles;
    video.value = res.data?.video;
    photo.value = res.data?.photo;
    archives.value = res.data?.archives;
    podcasts.value = res.data?.podcasts;

    articlesCount.value = res.data?.articles_count;
    videoCount.value = res.data?.video_count;
    photoCount.value = res.data?.photo_count;
    archivesCount.value = res.data?.archives_count;
    podcastsCount.value = res.data?.podcasts_count;

    tags_list.value = res.data?.tags;
    searchBlockKey.value++;
  })
})
</script>
