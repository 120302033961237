<template>
  <div class="home container-fluid">
    <a-breadcrumb>
      <template #separator>
        <icon>
          <template #component>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.875 14.25L12 10.125L7.875 6" stroke="#4C5965" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </template>
        </icon>
      </template>
      <a-breadcrumb-item><router-link to="/region">{{ $t('personDetailView.archive') }}</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/person">{{ $t('personDetailView.person') }}</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ title }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content-wrapper">
      <h3 class="page-title">{{ title }}</h3>
      <a-row>
        <a-col :span="8">
          <div class="person-image">
            <img :src="img" alt="" />
          </div>
          <div class="tags-list" v-if="tags.length">
            <a-tag v-for="tag in tags">{{ tag.label }}</a-tag>
            <a-button class="expand-button"><span></span></a-button>
          </div>
        </a-col>
        <a-col :span="16">
          <a-tabs
              class="person-details-tab"
              v-model:activeKey="activeTab"
              type="card"
          >
            <a-tab-pane key="personal" :tab="$t('personDetailView.personal_data')">
              <a-row>
                <a-col :span="6">
                  <a-list :data-source="personal" class="values-list">
                    <template #renderItem="{ item }">
                      <a-list-item>
                        <div class="list-label">{{ item.label }}</div>
                        <template #actions>
                          {{ item.value }}
                        </template>
                      </a-list-item>
                    </template>
                  </a-list>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="repression" :tab="$t('personDetailView.repressions')">
              <a-row>
                <a-col :span="6">
                  <a-list :data-source="repression" class="values-list">
                    <template #renderItem="{ item }">
                      <a-list-item>
                        <div class="list-label">{{ item.label }}</div>
                        <template #actions>
                          {{ item.value }}
                        </template>
                      </a-list-item>
                    </template>
                  </a-list>
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script setup>
import { useMeta } from "vue-meta";
import Service from "@/services/api";
import { onMounted, ref } from "vue";
import Icon from "@ant-design/icons-vue"
import { useRoute, useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'

const { t } = useI18n()


let title = ref('');
let img = ref(null)

const router = useRouter()
const route = useRoute()

let activeTab = ref('personal');
let personal = ref([])
let repression = ref([])
let tags = ref([])

onMounted(() => {
  Service.getPersonDetails(route.params.slug).then((res) => {
    title.value = res.data?.['fullname'];
    tags.value = res.data?.tags
    img.value = res.data?.img
    personal.value = [
      {
        label: t('personDetailView.year_of_birth'),
        value: res.data?.['birthdate']
      },
      {
        label: t('personDetailView.place_of_birth'),
        value: res.data?.['birthplace']
      },
      {
        label: t('personDetailView.nationality'),
        value: res.data?.['nationality']
      },
      {
        label: t('personDetailView.citizenship'),
        value: res.data?.['citizenship']
      },
      {
        label: t('personDetailView.social_status'),
        value: res.data?.['social_status']
      },
      {
        label: t('personDetailView.education'),
        value: res.data?.['education']
      },
      {
        label: t('personDetailView.party_membership'),
        value: res.data?.['partisanship']
      }
    ];
    repression.value = [
      {
        label: t('personDetailView.type_of_repression'),
        value: res.data?.['repression']
      },
      {
        label: t('personDetailView.place_of_work'),
        value: res.data?.['workplace']
      },
      {
        label: t('personDetailView.date_of_mobilization'),
        value: res.data?.['mobilization_date']
      },
      {
        label: t('personDetailView.termination_date'),
        value: res.data?.['termination_date']
      },
      {
        label: t('personDetailView.place_of_residence'),
        value: res.data?.['residence']
      },
      {
        label: t('personDetailView.occupation'),
        value: res.data?.['profession']
      },
      {
        label: t('personDetailView.mobilized_by'),
        value: res.data?.['mobilized_by']
      },
      {
        label: t('personDetailView.reason'),
        value: res.data?.['mobilization_reason']
      },
    ]
  });
})
useMeta({
  title: title.value,
  htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
});
</script>
