import { createApp } from "vue";
import App from "./App.vue"
import store from './store'
import router from "./router";
import Vuex from 'vuex'
import i18n from "./i18n"
import Antd from "ant-design-vue";
import { createMetaManager } from "vue-meta"
import VuePlyr from "vue-plyr";
import "ant-design-vue/dist/antd.css";
import "aos/dist/aos.css";
import "@/assets/style.css";
import "vue-plyr/dist/vue-plyr.css";

const app = createApp(App)
.use(router)
.use(i18n)
.use(store)
.use(Antd)
.use(createMetaManager())
.use(VuePlyr, {
    plyr: {}
})

app.mount('#app')
