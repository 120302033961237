<template>
  <div class="home container-fluid">
    <h3 class="page-title">{{ $t('contactView.title') }}</h3>
    <a-row>
      <a-col :span="10">
        <a-row justify="space-between" class="contacts-hints">
          <a-col :span="11">
            <a class="contacts-hint-block" href="mailto:de.archiv.kz2022@gmail.com">
              <i class="sms"></i>
              <div class="contacts-hint-block-title">{{ $t('contactView.write') }}</div>
              <div class="contacts-hint-block-content">{{ $t('contactView.to_email') }}</div>
              <div class="contacts-hint-block-desc">de.archiv.kz2022@gmail.com</div>
            </a>
          </a-col>
          <a-col :span="11">
            <a class="contacts-hint-block" href="tel:77172429395">
              <i class="phone"></i>
              <div class="contacts-hint-block-title">{{ $t('contactView.call_write') }}</div>
              <div class="contacts-hint-block-content">{{ $t('contactView.by_number') }}</div>
              <div class="contacts-hint-block-desc">+7 7172 429395</div>
            </a>
          </a-col>
        </a-row>
        <h4 class="contacts-hint">{{ $t('contactView.contact_us') }}</h4>
        <div class="faq-sidebar-form ml-0">
          <a-alert
              v-if="successText"
              message="Успешно"
              :description="successText"
              type="success"
              show-icon
              closable
              @close="onCloseSuccess"
              style="margin-bottom: 20px"
          />
          <a-alert
              v-if="errorDetails"
              message="Ошибка"
              :description="errorDetails"
              type="error"
              show-icon
              closable
              @close="onCloseError"
              style="margin-bottom: 20px"
          />
          <form action="#" @submit.prevent="suggest">
            <a-form-item>
              <a-input v-model:value="form.fio" type="text" :placeholder="$t('contactView.fio')">
                <template #prefix><UserOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input v-model:value="form.email" type="text" placeholder="E-mail">
                <template #prefix><MailOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input v-model:value="form.phone" v-maska data-maska="+7 (###) ###-##-##" type="text" :placeholder="$t('faqView.phone')">
                <template #prefix><MobileOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-textarea v-model:value="form.comment" :auto-size="{ minRows: 8, maxRows: 12 }" :placeholder="$t('contactView.write_text')" />
            </a-form-item>
            <a-form-item class="w-100 mb-0">
              <a-button type="primary" html-type="submit">
                {{ $t('contactView.send') }}
                <ArrowRightOutlined />
              </a-button>
            </a-form-item>
          </form>
        </div>
      </a-col>
      <a-col :span="14">
        <div class="contacts-sidebar">
          <h6>{{ $t('contactView.you_can_contact_us') }}</h6>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import { useMeta } from "vue-meta";
import Icon from "@ant-design/icons-vue";
import {ArrowRightOutlined, MailOutlined, MobileOutlined, UserOutlined} from "@ant-design/icons-vue/lib/icons";
import {reactive, ref} from "vue";
import Service from "@/services/api";
import { vMaska } from "maska"

const successText = ref(null)
const errorDetails = ref(null)

const onCloseSuccess = () => {
  successText.value = null
}
const onCloseError = () => {
  errorDetails.value = null
}

const form = reactive({
  fio: null,
  email: null,
  comment: null,
  phone: null,
})

const suggest = () => {
  Service.contact(form).then((res) => {
    if (res?.data?.id) {
      form.fio = null
      form.email = null
      form.comment = null
      form.phone = null
      errorDetails.value = null
      successText.value = 'Запрос отправлен'
    } else {
      errorDetails.value = 'Ошибка при отправлении запроса'
      successText.value = null
    }
  }).catch(({ response }) => {
    successText.value = null
    errorDetails.value = response?.data?.message
  });
}

useMeta({
  title: 'Contacts view',
  htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
});
</script>
