<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | De archiv` : `De archiv` }}</template>
  </metainfo>
  <div id="app">
    <router-view />
  </div>
</template>

<script setup>
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
