<template>
  <div class="home container-fluid">
    <a-breadcrumb>
      <template #separator>
        <icon>
          <template #component>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.875 14.25L12 10.125L7.875 6" stroke="#4C5965" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </template>
        </icon>
      </template>
      <a-breadcrumb-item><router-link to="/region">Архив</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/photo">Фото</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ title }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content-wrapper">
      <h3 class="page-title">{{ photo.title }}</h3>
      <div class="gallery">
        <div class="gallery-item" v-for="photo in grid" :style="`background-image: url('${photo.img}')`" @click="openImage(photo)">
        </div>
      </div>
<!--      <img :src="photo.img" alt="">-->
    </div>
    <a-modal v-model:visible="modal.visible" :title="photo.title" width="1460px" class="gallery-modal">
      <template #footer>
      </template>
      <a-row>
        <a-col :span="14" class="photo-src-wrap">
          <img :src="modal.img" alt="">
        </a-col>
        <a-col :span="10" class="photo-description-wrap">
          <h4>На фоторграфии:</h4>
          <div v-html="modal.content" class="photo-desc"></div>
        </a-col>
      </a-row>
      <a-row class="tags-list">
        <a-tag color="blue" v-for="tag in modal.tags">{{ tag.label }}</a-tag>
      </a-row>
    </a-modal>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import { useMeta } from "vue-meta";
import Icon from "@ant-design/icons-vue"
import Service from "@/services/api";
import { useRoute } from "vue-router";

let modal = reactive({
  visible: false,
  title: null,
  desc: null,
  src: null,
  tags: []
})

const route = useRoute();
let title = ref('');
let photo = ref({})

let grid = ref([])

const openImage = (photo) => {
  modal.visible = true
  modal.content = photo.content
  modal.img = photo.img
  modal.tags = photo.tags
}

onMounted(() => {
  Service.getPhotoDetails(route.params.slug).then((res) => {
    title.value = res.data?.title;
    photo.value = res.data;
    grid.value = res.data?.items;
  });
})
useMeta({
  title: title.value,
  htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
});
</script>
