import axios from "axios";

const axiosClient = axios.create({
    baseURL: 'https://api.de-archiv.kz/api',
});

// axios.defaults.withCredentials = true
axiosClient.interceptors.request.use((request) => {
    request.headers['x-locale'] = localStorage.getItem('locale') || 'ru';
    return request;
});

axiosClient.interceptors.response.use(undefined, (error) => {
    const { response } = error;
    const { data } = response;
    return Promise.reject(error)
    // alert(data.error?.details || "System error");
    // Notify({
    //     type: "warning",
    //     message: data.error?.details || "Системная ошибка, попбробуйте позже",
    // });
});

export default axiosClient;
