import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "@/views/HomeView";
import ArticlesView from "@/views/ArticlesView";
import ArticleDetailsView from "@/views/ArticleDetailsView";
import VideoView from "@/views/VideoView";
import VideoDetailsView from "@/views/VideoDetailsView";
import PhotoView from "@/views/PhotoView";
import PhotoDetailsView from "@/views/PhotoDetailsView";
import ArchivesView from "@/views/ArchivesView"
import PodcastsView from "@/views/PodcastsView"
import MainLayout from "@/layouts/MainLayout";
import SearchView from "@/views/SearchView";
import AdvancedSearchView from "@/views/AdvancedSearchView";
import SuggestView from "@/views/SuggestView";
import ContactsView from "@/views/ContactsView";
import FaqView from "@/views/FaqView";
import DeError404 from "@/views/404";
import DeError500 from "@/views/500";
import PersonView from "@/views/PersonView.vue";
import RegionView from "@/views/RegionView.vue";
import PersonDetailsView from "@/views/PersonDetailsView.vue";
import ArchivesDetailsView from "@/views/ArchivesDetailsView.vue";

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'main',
      component: MainLayout,
      children: [
        {
          path: '/',
          name: 'home',
          component: HomeView,
        },
        {
          path: '/articles',
          name: 'articles',
          component: ArticlesView,
        },
        {
          path: '/article/:slug',
          name: 'article-view',
          component: ArticleDetailsView,
        },
        {
          path: '/video',
          name: 'video',
          component: VideoView,
        },
        {
          path: '/video/:slug',
          name: 'video-view',
          component: VideoDetailsView,
        },
        {
          path: '/photo',
          name: 'photo',
          component: PhotoView,
        },
        {
          path: '/photo/:slug',
          name: 'photo-view',
          component: PhotoDetailsView,
        },
        {
          path: '/archives',
          name: 'archives',
          component: ArchivesView,
        },
        {
          path: '/archive/:id',
          name: 'archive-view',
          component: ArchivesDetailsView,
        },
        {
          path: '/podcasts',
          name: 'podcasts',
          component: PodcastsView,
        },
        {
          path: '/search',
          name: 'search',
          component: SearchView,
        },
        {
          path: '/region',
          name: 'region',
          component: RegionView,
        },
        {
          path: '/advanced-search',
          name: 'advanced-search',
          component: AdvancedSearchView,
        },
        {
          path: '/person',
          name: 'person',
          component: PersonView,
        },
        {
          path: '/person/:slug',
          name: 'person-view',
          component: PersonDetailsView,
        },
        {
          path: '/faq',
          name: 'faq',
          component: FaqView,
        },
        {
          path: '/suggest',
          name: 'suggest',
          component: SuggestView,
        },
        {
          path: '/contacts',
          name: 'contacts',
          component: ContactsView,
        },
        {
          path: '/not-found',
          name: 'route404',
          component: DeError404,
        },
        {
          path: '/server-error',
          name: 'route500',
          component: DeError500,
        },
      ],
    },
    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
});

// router.beforeEach((to, from, next) => {
  // redirect to 500 page
// })

export default router;
