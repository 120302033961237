import { createI18n } from 'vue-i18n'
import russian from './locales/ru'
import english from './locales/en'
import deutsch from './locales/de'

const locales = {
    'ru': russian,
    'en': english,
    'de': deutsch,
}

let locale = "ru"

if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', locale);
    document.querySelector('html').setAttribute('lang', locale);
}else {
    locale = localStorage.getItem('locale');
    document.querySelector('html').setAttribute('lang', locale)
}
export default createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: 'ru',
    messages: {
        'ru': locales['ru'].messages,
        'en': locales['en'].messages,
        'de': locales['de'].messages,
    },
})

