<template>
  <div class="home container-fluid">
    <div class="person-block">
      <div class="content-wrapper">
        <div class="article-content">
          <h3 class="article-title">Списки насильственно выселенных в Казахскую ССР немцев</h3>
          <p>«Списки насильственно выселенных в Казахскую ССР немцев» — это база данных, где собрана информация о прибытии и расселении депортированных в Казахстан немцев. <router-link to="article/baza-dannyx-predislovie">Подробнее</router-link></p>
        </div>
      </div>
      <h3 class="page-title">{{ $t('personView.search') }}</h3>
      <a-row>
        <a-col :span="24">
          <a-tabs v-model:activeKey="activeTab" type="card">
            <a-tab-pane key="personal">
              <a-row>
                <a-col :span="12">
                  <label>{{ $t('personView.personal_data.name') }}</label>
                  <a-input
                      v-model:value="params.firstname"
                      :placeholder="$t('personView.enter')"
                  />
                  <label>{{ $t('personView.personal_data.surname') }}</label>
                  <a-input
                      v-model:value="params.lastname"
                      :placeholder="$t('personView.enter')"
                  />
                </a-col>
                <a-col :span="12">
                  <label>{{ $t('personView.personal_data.year_of_birth') }}</label>
                  <a-date-picker
                      class="w-100"
                      v-model:value="params.birthdate"
                      :placeholder="$t('personView.select')"
                      value-format="YYYY"
                      picker="year"
                  />
                  <label>{{ $t('personView.personal_data.object') }}</label>
                  <a-select
                      class="w-100"
                      ref="select"
                      v-model:value="params.object"
                      :options="[{
                        value: null,
                        label: $t('personView.select')
                      }]"
                      show-search
                  />
                </a-col>
              </a-row>
            </a-tab-pane>
          </a-tabs>
          <a-row class="btns-wrap">
            <a-button @click="search" type="primary">{{ $t('personView.search_two') }}</a-button>
            <a-button @click="reset">{{ $t('personView.clear') }}</a-button>
          </a-row>
        </a-col>
      </a-row>

      <a-table
          :columns="columns"
          :data-source="data"
          :pagination="false"
          row-key="id"
          :custom-row="(record) => {
            return {
              onClick: () => {
                router.push({ name: 'person-view', params: { slug: record.slug } })
              }
            }
          }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'name'">
            <a>
              {{ record.name }}
            </a>
          </template>
        </template>
      </a-table>
      <a-pagination
          v-if="total > pageSize"
          v-model:current="params.page"
          v-model:pageSize="pageSize"
          :show-size-changer="false"
          :total="total"
          @change="getData(params)"
      />
    </div>
  </div>
</template>

<script setup>
import { useMeta } from "vue-meta";
import Icon from "@ant-design/icons-vue";
import {onMounted, reactive, ref, toRefs, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import Service from "@/services/api";
import {useI18n} from "vue-i18n";
useMeta({
  title: 'Person view',
  htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
});

const { t } = useI18n()

let params = reactive({
  page: 1,
  firstname: '',
  lastname: '',
  birthdate: '',
  object: null,
  state: '',
  city: '',
  district: '',
  residence_state: '',
  residence_city: '',
  residence_district: '',
  commissariat: '',
  date: '',
  cause: ''
});

const router = useRouter()
const route = useRoute()
const photo = ref([])

let pageSize = ref(10);
let total = ref(0);

let activeTab = ref('personal')

const causes = ref([
  { value: '', label: t('personView.select') },
  { value: 1, label: 'Причина №1' },
  { value: 2, label: 'Причина №2' },
])

const states = ref([])
const cities = ref([])
const districts = ref([])
const commissariats = ref([])

const columns = ref([{
  title: t('personView.personal_data.surname'),
  dataIndex: 'lastname',
  key: 'lastname',
}, {
  title: t('personView.personal_data.name'),
  dataIndex: 'firstname',
  key: 'firstname',
}, {
  title: t('personView.personal_data.patronymic'),
  dataIndex: 'patronymic',
  key: 'patronymic',
}, {
  title: t('personView.personal_data.year'),
  key: 'birthdate',
  dataIndex: 'birthdate',
}, {
  title: t('personView.place_of_birth.title'),
  key: 'birthplace',
  dataIndex: 'birthplace',
}, {
  title: t('personView.personal_data.object'),
  key: 'camp',
  dataIndex: 'camp',
}]);

const data = ref([])

const getData = (params) => {
  Service.getPerson(params).then((res) => {
    data.value = res.data.data
    states.value = res.data.states
    cities.value = res.data.cities
    districts.value = res.data.districts
    commissariats.value = res.data.commissariats
    pageSize.value = res.data?.meta?.per_page;
    total.value = res.data?.meta?.total;
  });
}

const search = () => {
  getData(params)
}

const reset = () => {
  params.firstname = ''
  params.lastname = ''
  params.birthdate = ''
  params.object = null
  params.state = ''
  params.city = ''
  params.district = ''
  params.residence_state = ''
  params.residence_city = ''
  params.residence_district = ''
  params.commissariat = ''
  params.date = ''
  params.cause = ''
  getData(params)
}

onMounted(() => {
  getData(params);
})

let { page } = toRefs(params)

</script>
<style>
.article-content{
  text-align: center;
}
</style>