<template>
  <div class="home container-fluid">
    <a-breadcrumb>
      <template #separator>
        <icon>
          <template #component>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.875 14.25L12 10.125L7.875 6" stroke="#4C5965" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </template>
        </icon>
      </template>
      <a-breadcrumb-item><router-link to="/region">Архив</router-link></a-breadcrumb-item>
      <a-breadcrumb-item><router-link to="/video">Видео</router-link></a-breadcrumb-item>
      <a-breadcrumb-item>{{ title }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content-wrapper">
      <div class="player-container">
<!--        <vue-plyr ref="plyr">-->
<!--          <video-->
<!--              controls-->
<!--              playsinline-->
<!--              :data-poster="video.poster"-->
<!--              :src="video.src"-->
<!--          ></video>-->
<!--        </vue-plyr>-->
        <div class="plyr plyr--full-ui plyr--video plyr--html5 plyr--fullscreen-enabled plyr--paused plyr--stopped plyr--pip-supported">
          <div class="plyr__video-wrapper">
            <iframe :src="video.src" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <h3 class="page-title">{{ video.title }}</h3>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import Icon from "@ant-design/icons-vue"
import Service from "@/services/api";
import { useRoute } from "vue-router";

const plyr = ref(null)

const route = useRoute();
let title = ref('');
let video = ref({})

onMounted(() => {
  Service.getVideoDetails(route.params.slug).then((res) => {
    title.value = res.data?.title;
    video.value = res.data;
  });
})
useMeta({
  title: title.value,
  htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
});
</script>
<style>
.plyr__control.plyr__control--overlaid {
  background: none;
  border: 2px solid #fff;
  opacity: 0.8;
  transition: all 0.2s ease;
}
.plyr--video .plyr__control:hover {
  background: none !important;
  opacity: 1;
  transition: all 0.2s ease;
}
:not(:root):fullscreen video {
  max-height: 100% !important;
  min-width: 100%;
  position: initial !important;
}
.plyr:-webkit-full-screen .plyr__video-wrapper{
  padding: 0;
}
.plyr__video-wrapper{
  padding-bottom: 53%;
  height: 823px;
}
iframe {
  height: 823px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}
</style>
