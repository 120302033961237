<template>
  <div class="home container-fluid">
    <div id="search-results" ref="searchResultsDiv" v-if="searchResults" class="container-fluid">
      <div class="loader" v-if="resultsLoading">
        <a-skeleton active class="carousel-skeleton-header" :paragraph="false" width="100" />
        <div class="carousel-skeleton-wrapper">
          <div class="carousel-skeleton">
            <a-skeleton-input active class="carousel-skeleton-image" size="large" rows="3" />
            <a-skeleton active class="carousel-skeleton-title" :title="false" shape="square" size="large" />
          </div>
          <div class="carousel-skeleton">
            <a-skeleton-input active class="carousel-skeleton-image" size="large" rows="3" />
            <a-skeleton active class="carousel-skeleton-title" :title="false" shape="square" size="large" />
          </div>
          <div class="carousel-skeleton">
            <a-skeleton-input active class="carousel-skeleton-image" size="large" rows="3" />
            <a-skeleton active class="carousel-skeleton-title" :title="false" shape="square" size="large" />
          </div>
          <div class="carousel-skeleton">
            <a-skeleton-input active class="carousel-skeleton-image" size="large" rows="3" />
            <a-skeleton active class="carousel-skeleton-title" :title="false" shape="square" size="large" />
          </div>
          <div class="carousel-skeleton">
            <a-skeleton-input active class="carousel-skeleton-image" size="large" rows="3" />
            <a-skeleton active class="carousel-skeleton-title" :title="false" shape="square" size="large" />
          </div>
        </div>
      </div>
      <div v-else>
        <Carousel
            :key="carouselKey"
            v-if="articles.length"
            :data="articles"
            :count="articlesCount"
            :title="'Статьи'"
            :index-page="'articles'"
            :params="params"
            :details-page="'article-view'"
        />
        <Carousel
            :key="carouselKey"
            v-if="video.length"
            :data="video"
            :count="videoCount"
            :title="'Видео'"
            :index-page="'video'"
            :params="params"
            :details-page="'video-view'"
        />
        <Carousel
            :key="carouselKey"
            v-if="photo.length"
            :data="photo"
            :count="photoCount"
            :title="'Фото'"
            :index-page="'photo'"
            :params="params"
            :details-page="'photo-view'"
        />
        <Carousel
            :key="carouselKey"
            v-if="archives.length"
            :data="archives"
            :count="archivesCount"
            :title="'Семейные архивы'"
            :index-page="'archives'"
            :params="params"
        />
        <Carousel
            :key="carouselKey"
            v-if="podcasts.length"
            :data="podcasts"
            :count="podcastsCount"
            :title="'Подкасты и интервью'"
            :index-page="'podcasts'"
            :params="params"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMeta } from "vue-meta";
import {nextTick, onMounted, reactive, ref} from "vue";
import Service from "@/services/api";
import Carousel from "@/components/Carousel.vue";
import AOS from "aos";

let params = reactive({
  search: '',
  tags: [],
  region: null,
})

let carouselKey = ref(0)

let tags_list = ref([])

let articles = ref([])
let video = ref([])
let photo = ref([])
let archives = ref([])
let podcasts = ref([])

let articlesCount = ref(0)
let videoCount = ref(0)
let photoCount = ref(0)
let archivesCount = ref(0)
let podcastsCount = ref(0)

let searchResults = ref(false)
let resultsLoading = ref(false)
let countsLoading = ref(true)

onMounted(() => {
  AOS.init();
  search(0);
  Service.getSearchResults({region:0}).then((res) => {
    archivesCount.value = res.data?.['archives_count']
    articlesCount.value = res.data?.['articles_count']
    photoCount.value = res.data?.['photo_count']
    podcastsCount.value = res.data?.['podcasts_count']
    videoCount.value = res.data?.['video_count']
  }).finally(() => {
    countsLoading.value = false
  })
})

const search = function (regionId) {
  params.region = regionId
  resultsLoading.value = true
  searchResults.value = true
  nextTick(() => {
    let div = document.getElementById('search-results');
    if (div)
      div.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
  })
  articles.value = []
  video.value = []
  photo.value = []
  archives.value = []
  podcasts.value = []

  // articlesCount.value = 0
  // videoCount.value = 0
  // photoCount.value = 0
  // archivesCount.value = 0
  // podcastsCount.value = 0

  updateData(params)
}

const updateData = (payload) => {
  return Service.getSearchResults(payload).then((res) => {
    resultsLoading.value = false
    articles.value = res.data?.articles;
    video.value = res.data?.video;
    photo.value = res.data?.photo;
    archives.value = res.data?.archives;
    podcasts.value = res.data?.podcasts;

    articlesCount.value = res.data?.articles_count;
    videoCount.value = res.data?.video_count;
    photoCount.value = res.data?.photo_count;
    archivesCount.value = res.data?.archives_count;
    podcastsCount.value = res.data?.podcasts_count;

    carouselKey.value++;

    tags_list.value = res.data?.tags;
  }).finally(() => {
    document.getElementById('search-results').scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  })
}

useMeta({
  title: 'Region view',
  htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
});
</script>
