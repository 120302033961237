const messages = {
  navbar: {
    home: 'Home',
    search: 'Search',
    faq: 'FAQ',
    suggestMaterial: 'Suggest material',
    contacts: 'Contacts',
    map: 'Show map',
    advancedSearch: 'Advanced search',
    person: 'Person'
  },
  homeView: {
    title: 'INTERAKTIVES ARCHIV DER DEUTSCHEN KASACHSTANS',
    body: 'You are on the website of the Interactive Archive of the Germans of Kazakhstan, where are collected and presented the materials from state and family archives on the history and culture of the Kazakh Germans.\n' +
        '\n' +
        'When studying the past, we turn to various sources, including archival documents that silently store information about past events, phenomena, facts, and names. Is it really silent? By carefully collecting, researching and preserving information from archives on various topics in the history and culture of the Kazakh Germans, we saw that archival materials can often loudly “voice” certain problems of the past. And all the more valuable today is turning to documentary history in search of answers to many pressing questions and challenges of our time.',
    main_title: 'WHAT IS THE ARCHIVE USEFUL FOR YOU PERSONALLY?',
    main_desc: 'From 2022 to 2023 carried out the project team a lot of work in the state archives of the Republic of Kazakhstan, collecting materials from the family archives of Kazakh Germans. The identified set of materials was digitized and systematized into the presented categories. Using the site you will have the opportunity to get acquainted with various topics on the history and culture of the Germans of Kazakhstan.\n' +
        '\n' +
        'Please note that the site contains electronic copies of archival documents and the site does not provide archive certificates. To obtain archive certificates, you should contact departmental and state archival institutions of the Republic of Kazakhstan.',
    how_it_works_title: 'HOW IT WORK?',
    how_it_works_desc: 'You can go to “Advanced Search” to see all the publications available on the site, divided into sections, or find the information you need specifically, and click on an area on the map on the right to see all publications related to this area. The same thing can be done using the “Search” menu item.\n' +
        '\n' +
        'By accessing the “Database” menu item, you can search for a specific person and information about him.\n' +
        '\n' +
        'If you have materials that you would like to submit for placement on our website, use the “Submit Materials” menu item.\n',
    copyright: 'All materials on the site are provided for informational purposes only. Partial or complete copying of site materials without written permission from the site administration is prohibited! All materials on the site are protected in accordance with the law, including copyright and related rights.',
    open_map: 'Open the map',
    advancedSearch: 'Advanced search',
    articles: 'Articles',
    photo: 'Photos',
    video: 'Video',
    podcasts: 'Podcasts and interviews',
    archives: 'Family archives',
    search_throughout: 'Search throughout the territory of the Republic of Kazakhstan',
    word_for_search: 'Enter the words you want to search for or use the tag system',
    search_by_word: 'Search by word',
    enter_the_tag: 'Enter the tag',
    clear_all: 'Clear all'
  },
  advancedSearchView: {
    advancedSearch: 'Advanced search',
    tema: 'Topic',
    region: 'Region',
    location_archive: 'Archive location',
    period: 'Period',
    select_date: 'Select date',
    articles: 'Articles',
    photo: 'Photos',
    video: 'Video',
    podcasts: 'Podcasts and interviews',
    archives: 'Family archives',
    show_all: 'Watch all',
  },
  searchView: {
    articles: 'Articles',
    photo: 'Photos',
    video: 'Video',
    podcasts: 'Podcasts and interviews',
    archives: 'Family archives',
    search_throughout: 'Search throughout the territory of the Republic of Kazakhstan',
    word_for_search: 'Enter the words you want to search for or use the tag system',
  },
  faqView: {
    title: 'Didn\'t find the answer to the question?',
    second_title: 'Ask us directly',
    name: 'Full name',
    phone: 'Phone number',
    question: 'Question',
    send: 'Send'
  },
  suggestView: {
    title: 'Suggest materials',
    name: 'Last and First name',
    text: 'What materials do you have?',
    send: 'Send',
    text_two: {
      first_line: 'Please fill out the form with a detailed description of the' ,
      second_line: 'available materials. We will contact you',
      third_line: 'via the email address specified in the application'
    }
  },
  contactView: {
    title: 'Contacts',
    write: 'Write',
    to_email: 'to email',
    call_write: 'Call',
    by_number: 'by number',
    contact_us: 'Contact us by filling out the form below',
    fio: 'Last and First name',
    phone_number: 'Phone number',
    write_text: 'Write a message',
    send: 'Send',
    you_can_contact_us: 'You can contact us in a way that is convenient for you',
  },
  personView: {
    search: 'Search',
    enter: 'Enter',
    select: 'Select',
    search_two: 'Search',
    clear: 'Clear',
    please_provide: 'Please provide the most correct data',
    personal_data: {
      title: 'Personal data',
      name: 'Name',
      surname: 'Surname',
      patronymic: 'Patronymic',
      year_of_birth: 'Year of birth',
      object: 'Object',
      year: 'Year',
    },
    place_of_birth: {
      title: 'Place of birth',
      region: 'Region',
      district: 'District / area',
      city: 'Locality / city',
      meo: 'Military enlistment office',
      date: 'Date'
    },
    place_of_residence: {
      title: 'Place of residence',
      period: 'Period',
      cause: 'Reason for departure'
    }
  },
  personDetailView: {
    person: 'Personality',
    archive: 'Archive',
    personal_data: 'Personal data',
    year_of_birth: 'Year of birth',
    place_of_birth: 'Place of birth',
    nationality: 'Nationality',
    citizenship: 'Citizenship',
    social_status: 'Social status',
    education: 'Education',
    party_membership: 'Party membership',
    repressions: 'Information about repressions',
    type_of_repression: 'Type of repression',
    place_of_work: 'Place of work',
    date_of_mobilization: 'Date of mobilization',
    termination_date: 'Termination date',
    place_of_residence: 'Place of residence',
    occupation: 'Occupation',
    mobilized_by: 'Mobilized by',
    reason: 'Reason',
  },
  articles: {
    title: 'Articles',
    did_you_know_that: 'Did you know that…',
    did_you_know_more: 'Some concepts do not exist in any other language. The word "fremdschämen", for example, describes a feeling of awkwardness or shame for the act of another person. And "Fernweh" is the opposite of "Heimweh" (homesickness) and describes the craving for long journeys and adventures.'
  }
}

export default {
  locale: 'en',
  messages,
}
