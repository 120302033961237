<template>
  <div class="home container-fluid">
    <h3 class="page-title">FAQ</h3>
    <a-row>
      <a-col :span="16">
        <div class="faq-wrapper">
          <a-collapse v-model:activeKey="activeKey" expand-icon-position="right" ghost>
            <template #expandIcon="{ isActive }">
              <icon :rotate="isActive ? 180 : 0">
                <template #component>
                  <svg :style="{ transform: `rotate(${isActive ? '0deg' : '180deg'})`}" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 3.5C16.9706 3.5 21 7.52944 21 12.5C21 17.4706 16.9706 21.5 12 21.5C7.02944 21.5 3 17.4706 3 12.5C3 7.52944 7.02944 3.5 12 3.5Z" stroke="#0769B4" stroke-width="2" stroke-miterlimit="10"/>
                    <path d="M15.375 11L12 14.75L8.625 11" stroke="#0769B4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </template>
              </icon>
            </template>
            <a-collapse-panel v-for="item in faq" :key="item.id" :header="item.question">
              <p v-html="item.answer"></p>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="faq-sidebar">
          <h6>{{ $t('faqView.title') }} <br> {{ $t('faqView.second_title') }}</h6>
        </div>
        <div class="faq-sidebar-form">
          <a-alert
              v-if="successText"
              message="Успешно"
              :description="successText"
              type="success"
              show-icon
              closable
              @close="onCloseSuccess"
              style="margin-bottom: 20px"
          />
          <a-alert
              v-if="errorDetails"
              message="Ошибка"
              :description="errorDetails"
              type="error"
              show-icon
              closable
              @close="onCloseError"
              style="margin-bottom: 20px"
          />
          <form action="#" @submit.prevent="send">
            <a-form-item>
              <a-input v-model:value="form.name" type="text" :placeholder="$t('faqView.name')">
                <template #prefix><UserOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input v-model:value="form.email" type="text" placeholder="E-mail">
                <template #prefix><MailOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input v-model:value="form.phone" v-maska data-maska="+7 (###) ###-##-##"  type="text" :placeholder="$t('faqView.phone')">
                <template #prefix><MobileOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-textarea v-model:value="form.question" :auto-size="{ minRows: 5, maxRows: 8 }" :placeholder="$t('faqView.question')" />
            </a-form-item>
            <a-form-item class="w-100 mb-0">
              <a-button type="primary" html-type="submit">
                {{ $t('faqView.send') }}
                <ArrowRightOutlined />
              </a-button>
            </a-form-item>
          </form>
        </div>
      </a-col>
    </a-row>
  </div>
</template>


<script setup>
import {onMounted, reactive, ref} from "vue";
import { useMeta } from "vue-meta";
import Icon from "@ant-design/icons-vue";
import { UserOutlined, MailOutlined, MobileOutlined, ArrowRightOutlined } from "@ant-design/icons-vue/lib/icons";
import Service from "@/services/api";
import { vMaska } from "maska"

let activeKey = ref(1)

const faq = ref([])

const successText = ref(null)
const errorDetails = ref(null)

const onCloseSuccess = () => {
  successText.value = null
}
const onCloseError = () => {
  errorDetails.value = null
}

const form = reactive({
  name: null,
  email: null,
  phone: null,
  question: null
})

const send = () => {
  Service.sendQuestion(form).then((res) => {
    if (res?.data?.id) {
      form.name = null
      form.email = null
      form.phone = null
      form.question = null
      errorDetails.value = null
      successText.value = 'Ворпос отправлен'
    } else {
      errorDetails.value = 'Ошибка при отправлении вопроса'
      successText.value = null
    }
  }).catch(({ response }) => {
    successText.value = null
    errorDetails.value = response?.data?.message
  });
}

onMounted(() => {
  Service.getFaq().then(({ data }) => {
    faq.value = data.data
  });
})

useMeta({
  title: 'Faq view',
  htmlAttrs: { lang: 'en', amp: true }
});
</script>
