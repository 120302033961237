<template>
  <div class="home container-fluid">
    <h3 class="page-title">{{ $t('suggestView.title') }}</h3>
    <a-row>
      <a-col :span="10">
        <div class="faq-sidebar-form ml-0">
          <a-alert
              v-if="successText"
              message="Успешно"
              :description="successText"
              type="success"
              show-icon
              closable
              @close="onCloseSuccess"
              style="margin-bottom: 20px"
          />
          <a-alert
              v-if="errorDetails"
              message="Ошибка"
              :description="errorDetails"
              type="error"
              show-icon
              closable
              @close="onCloseError"
              style="margin-bottom: 20px"
          />
          <form action="#" @submit.prevent="suggest">
            <a-form-item>
              <a-input v-model:value="form.fio" type="text" :placeholder="$t('suggestView.name')">
                <template #prefix><UserOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input v-model:value="form.email" type="text" placeholder="E-mail">
                <template #prefix><MailOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-textarea v-model:value="form.comment" :auto-size="{ minRows: 8, maxRows: 12 }" :placeholder="$t('suggestView.text')" />
            </a-form-item>
            <a-form-item class="w-100 mb-0">
              <a-button type="primary" html-type="submit">
                {{ $t('suggestView.send') }}
                <ArrowRightOutlined />
              </a-button>
            </a-form-item>
          </form>
        </div>
      </a-col>
      <a-col :span="14">
        <div class="suggest-sidebar">
          <h6>
            {{ $t('suggestView.text_two.first_line') }}
            <br>
            {{ $t('suggestView.text_two.second_line') }}
            <br>
            {{ $t('suggestView.text_two.third_line') }}
          </h6>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import { useMeta } from "vue-meta";
import {ArrowRightOutlined, MailOutlined, MobileOutlined, UserOutlined} from "@ant-design/icons-vue/lib/icons";
import Service from "@/services/api";
import {reactive, ref} from "vue";

const successText = ref(null)
const errorDetails = ref(null)

const onCloseSuccess = () => {
  successText.value = null
}
const onCloseError = () => {
  errorDetails.value = null
}

const form = reactive({
  fio: null,
  email: null,
  comment: null,
})

const suggest = () => {
  Service.suggest(form).then((res) => {
    if (res?.data?.id) {
      form.fio = null
      form.email = null
      form.comment = null
      errorDetails.value = null
      successText.value = 'Запрос отправлен'
    } else {
      errorDetails.value = 'Ошибка при отправлении запроса'
      successText.value = null
    }
  }).catch(({ response }) => {
    successText.value = null
    errorDetails.value = response?.data?.message
  });
}

useMeta({
  title: 'Suggest view',
  htmlAttrs: { lang: localStorage.getItem('locale'), amp: true }
});
</script>
