import axiosClient from "@/services/axios";

class Service {
    constructor() {
        this.$api = axiosClient;
    }

    getSearchResults(payload) {
        const region = payload.region ?? 0
        return this.$api.get(`search-results/${region}`, { params: payload });
    }

    getArticles(params) {
        return this.$api.get('articles', { params });
    }

    getArticleDetails(slug) {
        return this.$api.get(`article/${slug}`);
    }

    getVideo(params) {
        return this.$api.get('video', { params });
    }

    getVideoDetails(slug) {
        return this.$api.get(`video/${slug}`);
    }

    getPhoto(params) {
        return this.$api.get('photo', { params });
    }

    getPhotoDetails(slug) {
        return this.$api.get(`photo/${slug}`);
    }

    getArchives(params) {
        return this.$api.get('archives', { params });
    }

    getPodcasts(params) {
        return this.$api.get('podcasts', { params });
    }

    getReferences() {
        return this.$api.get('references');
    }

    getPerson(params) {
        return this.$api.get('/person', { params })
    }

    getPersonDetails(slug) {
        return this.$api.get(`person/${slug}`);
    }

    getFaq() {
        return this.$api.get('/faq')
    }

    sendQuestion(data) {
        return this.$api.post('/faq/send-question', data)
    }

    suggest(data) {
        return this.$api.post('/suggest', data)
    }

    contact(data) {
        return this.$api.post('/contact', data)
    }

    getArchiveDetails(slug) {
        return this.$api.get(`archive/${slug}`);
    }
}

export default new Service()